// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import "./controllers"
import Rails from "@rails/ujs"
import Chartkick from "chartkick"
import Chart from "chart.js/auto"
import 'chartjs-adapter-moment'
import Highcharts from "highcharts"

// Initialize Chartkick with Highcharts
Chartkick.use(Highcharts)

// Start Rails UJS
Rails.start()

// Make Highcharts globally available if needed
window.Highcharts = Highcharts

// If you want to use Chart.js as well
window.Chart = Chart

// Chartkick configuration
Chartkick.options = {
  adapter: "highcharts"
}

// import $ from 'jquery';
// window.$ = $;
// global.$ = global.jQuery = $;

// import 'select2';
// import 'select2/dist/css/select2.css';
